<template>
  <div class="lead-form-container">
    <header class="sticky-header">
      <h1>{{ computedData.holder.firstName }} {{ computedData.holder.lastName }}</h1>
      <div class="action-buttons">
        <CButton color="info" @click="showAddModal">In Deal umwandeln</CButton>
        <CButton v-if="computedData.pdfFile" color="secondary" :href="computedData.pdfFile.url" target="_blank">
          <CIcon name="cib-adobe-acrobat-reader" /> Wohnmarktanalyse (PDF)
        </CButton>
      </div>
    </header>

    <div class="lead-info-card">
      <div class="lead-type">
        <CSelect
          label="Typ des Leads"
          :value="computedData.type"
          @update:value="computedData.type = $event"
          :options="leadTypeOptions"
        />
      </div>
      <div class="lead-details">
        <p>{{ computedData.realm.name }} (ID: {{ computedData.realm.id }})</p>
        <p v-if="computedData.imported" class="imported-label">Importiert durch Zapier</p>
      </div>
    </div>

    <CTabs variant="pills" class="modern-tabs">
      <CTab title="Ansprechpartner:in" icon="cil-user">
        <div class="tab-content">
          <CRow>
            <CCol sm="4">
              <CInput label="Anrede" v-model="computedData.holder.salutation" placeholder="Frau/Herr/Dr..."/>
            </CCol>
            <CCol sm="4">
              <CInput label="Vorname" v-model="computedData.holder.firstName"/>
            </CCol>
            <CCol sm="4">
              <CInput label="Nachname" v-model="computedData.holder.lastName"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6">
              <CInput label="Telefonnummer" v-model="computedData.holder.phoneNumber" placeholder="+49 (0) ..."/>
            </CCol>
            <CCol sm="6">
              <CInput label="E-Mail" v-model="computedData.holder.email" placeholder="max@mustermann.de"/>
            </CCol>
          </CRow>
        </div>
      </CTab>
      <CTab v-if="computedData.values">
        <template slot="title">
          <CIcon name="cil-location-pin"/>
          Anschrift
        </template>
        <div class="tab-content">
          <CRow>
            <CCol>
              <CInput label="Adresse" v-model="computedData.values.address.formattedAddress"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <CInput label="Längengrad" v-model="computedData.values.address.longitude"/>
            </CCol>
            <CCol>
              <CInput label="Breitengrad" v-model="computedData.values.address.latitude"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="9">
              <CInput label="Straße" v-model="computedData.values.address.street"/>
            </CCol>
            <CCol sm="3">
              <CInput label="Hausnummer" v-model="computedData.values.address.streetNumber"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="4">
              <CInput label="PLZ" v-model="computedData.values.address.postalCode"/>
            </CCol>
            <CCol sm="8">
              <CInput label="Ort" v-model="computedData.values.address.city"/>
            </CCol>
          </CRow>
        </div>
      </CTab>
      <CTab v-if="computedData.values">
        <template slot="title">
          <CIcon name="cil-calculator"/>
          Objekt
        </template>
        <div class="tab-content">
          <CRow>
            <CCol>
              <CInput label="Baujahr" v-model="computedData.values.constructionYear"/>
            </CCol>
            <CCol>
              <CInput label="Zimmer" v-model="computedData.values.rooms"/>
            </CCol>
            <CCol>
              <CInput label="Wohnfläche (m²)" v-model="computedData.values.livingArea"/>
            </CCol>
            <CCol>
              <CInput label="Grundfläche (m²)" v-model="computedData.values.siteArea"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="3">
              <CInput label="Etage" v-model="computedData.values.floor"/>
            </CCol>
            <CCol sm="3">
              <CInput label="Objektkategorie (Miete)" v-model="computedData.values.objectCategory"/>
            </CCol>
            <CCol sm="3">
              <CInput label="Grund der Bewertung" v-model="computedData.values.reason"/>
            </CCol>
            <CCol sm="3">
              <CInput label="Verkaufszeitwunsch" v-model="computedData.values.reasonWhen"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="3">
              <CInput label="Erschlossen (Grundstücke)" v-model="computedData.values.developedProperty"/>
            </CCol>
            <CCol sm="3">
              <CInput label="Bebauung (Grundstücke)" v-model="computedData.values.constructionPossibilities"/>
            </CCol>
            <CCol sm="3">
              <CInput label="Zuschnitt (Grundstücke)" v-model="computedData.values.siteLayout"/>
            </CCol>
          </CRow>
        </div>
      </CTab>
      <CTab v-if="computedData.results && computedData.results.resultAbsolute">
        <template slot="title">
          <CIcon name="cil-graph"/>
          Wertermittlung
        </template>
        <div class="tab-content">
          <CRow>
            <CCol>
              <FocusableMoneyInput label="Marktwert" v-model="computedData.results.resultAbsolute"/>
            </CCol>
            <CCol>
              <FocusableMoneyInput label="Mindestwert" v-model="computedData.results.lowAbsolute"/>
            </CCol>
            <CCol>
              <FocusableMoneyInput label="Maximalwert" v-model="computedData.results.highAbsolute"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <FocusableMoneyInput label="Wert pro m² Wohnfläche" v-model="computedData.results.resultPerSqm"/>
            </CCol>
            <CCol>
              <FocusableMoneyInput label="Mindestwert pro m²" v-model="computedData.results.lowPerSqm"/>
            </CCol>
            <CCol>
              <FocusableMoneyInput label="Maximaler Wert pro m²" v-model="computedData.results.highPerSqm"/>
            </CCol>
          </CRow>
        </div>
      </CTab>
      <CTab v-if="computedData.activities">
        <template slot="title">
          <CIcon name="cil-bar-chart"/>
          E-Mail Tracking
        </template>
        <div class="tab-content">
          <CListGroup class="mb-3 mt-3">
            <CListGroupItem v-for="activity in computedData.activities" :key="activity.id" href="#"
                            class="lead-activity flex-column align-items-start">
              <CRow>
                <CCol xs class="ml-2">
                  <CIcon :name="getIconForActivityType(activity.type)"/>
                </CCol>
                <CCol>
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{ activity.subject }}</h5>
                    <small class="lead-activity-from-now">{{ $moment(activity.created).fromNow() }}</small>
                    <small class="lead-activity-created-format">{{ $moment(activity.created).format("D. MMMM YYYY, HH:mm:ss") }}</small>
                  </div>
                  <p class="mb-1">
                    {{ activity.message }}
                  </p>
                </CCol>
              </CRow>
            </CListGroupItem>
          </CListGroup>
        </div>
      </CTab>
      <CTab>
        <template slot="title">
          <CIcon name="cil-user"/>
          Aktivitäten
        </template>
        <div class="tab-content">
          <CListGroup class="mb-3 mt-3">
            <TaskWidget/>
          </CListGroup>
        </div>
      </CTab>
      <CTab>
        <template slot="title">
          <CIcon name="cil-user"/>
          Extra Schritte
        </template>
        <div class="tab-content">
          <CRow v-if="(computedData.extraSteps || []).length > 0">
            <CCol :key="index" v-for="(extraStep, index) in computedData.extraSteps" sm="3">
              <CInput v-if="computedData.type.toUpperCase() === 'BASIC'" :label="extraStep.title" :value="extraStep.answer"/>
              <CInput v-else :label="extraStep.title" :value="extraStep.answer" :description="'Wertänderung: ' + (extraStep.change > 0 ? '+' : '') + extraStep.change + ((extraStep.changeType || 'PERCENTUAL').toUpperCase() === 'PERCENTUAL' ? '%' : '€' )"/>
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol>
              <CAlert color="warning">
                Keine Extra Schritte für diesen Lead vorhanden.
              </CAlert>
            </CCol>
          </CRow>
        </div>
      </CTab>
    </CTabs>


    <CRow>
      <CCol md="12">
        <CForm @submit.prevent="submit">
          <CModal
            :title="mode + ' deal'"
            :show.sync="visibleModal"
            :closeOnBackdrop="false"
          >
            <AddDealWidget
              :pipelines="pipelines"
              :originalPipelines="originalPipelines"
              :applyAdd="applyAdd"
              :applyUpdate="applyUpdate"
              :mode="mode"
              ref="dealWidget"
            />
            <div slot="footer">
              <CButton
                class="mr-3"
                color="dark"
                variant="outline"
                @click="closeDealWidget"
              >
                Abbrechen
              </CButton>
              <CButton color="primary" type="submit">
                <CIcon name="cil-save" /> Save
              </CButton>
            </div>
          </CModal>
        </CForm>
      </CCol>
    </CRow>
  </div>
</template>





<script>
import FocusableMoneyInput from '@/components/FocusableMoneyInput';
import TaskWidget from './TaskWidget';
import AddDealWidget from './modal/AddDealWidget';

export default {
  name: 'LeadForm',
  components: {
    FocusableMoneyInput,
    TaskWidget,
    AddDealWidget,
    activeTab: 'contact',
  },
  props: {
    passedData: {
      type: Object,
      default: () => {
        return {
          'id': 0,
          'realm': {'id': 0, 'name': '', 'wordpressUrl': '', 'zapierSubscribe': null, 'status': ''},
          'type': '',
          'holder': {'id': 0, 'salutation': '', 'firstName': '', 'lastName': '', 'phoneNumber': '', 'email': ''},
          'values': {
            'id': 0,
            'address': {
              'id': 0,
              'formattedAddress': '',
              'longitude': 0,
              'latitude': 0,
              'street': '',
              'streetNumber': '',
              'postalCode': '',
              'city': ''
            },
            'constructionYear': 1990,
            'rooms': '',
            'livingArea': 0,
            'siteArea': 0,
            'floor': '',
            'objectCategory': ''
          },
          'results': {
            'id': 0,
            'resultAbsolute': 0,
            'lowAbsolute': 0,
            'highAbsolute': 0,
            'resultPerSqm': 0,
            'lowPerSqm': 0,
            'highPerSqm': 0
          },
          'activities': [
            {'id': 0, 'type': 'GENERAL', 'subject': '', 'message': '', 'created': ''}
          ],
          'pdfFile': {
            'url': ''
          },
          'created': '',
          'updated': '',
          leadTypeOptions: [
          {label: 'Wohnung', value: 'APARTMENT'},
          {label: 'Haus', value: 'HOUSE'},
          {label: 'Grundstück', value: 'LAND'},
          {label: 'Mehrfamilienhaus', value: 'APARTMENT_HOUSE'},
          {label: 'Mietwohnung', value: 'RENT_APARTMENT'},
          {label: 'Miethaus', value: 'RENT_HOUSE'},
          {label: 'Gewerbe', value: 'BUSINESS'},
          {label: 'Basic', value: 'BASIC'}
        ]
        }
      },
    },
    btnText: {
      type: String,
      required: true
    }
  },
  computed: {
  computedData() {
    return this.passedData || {
      holder: {},
      values: { address: {} },
      results: {},
      activities: [],
      realm: {}
    };
  },
},

  data() {
  return {
    activeTab: 'contact',
    mode: 'add',
    pipelines: [],
    originalPipelines: [],
    visibleModal: false,
    leadTypeOptions: [
      { label: 'Wohnung', value: 'APARTMENT' },
      { label: 'Haus', value: 'HOUSE' },
      { label: 'Grundstück', value: 'LAND' },
      { label: 'Mehrfamilienhaus', value: 'APARTMENT_HOUSE' },
      { label: 'Mietwohnung', value: 'RENT_APARTMENT' },
      { label: 'Miethaus', value: 'RENT_HOUSE' },
      { label: 'Gewerbe', value: 'BUSINESS' },
      { label: 'Basic', value: 'BASIC' }
    ]
    }
  },
  methods: {
    submit() {
      // Hier rufst du die `saveLead` Methode auf
      this.saveLead(this.computedData);
    },
    async saveLead(formData) {
      try {
        const leadId = this.passedData.id;
        const response = await axios.put(`/lead/${leadId}`, formData);
        if (response.data.code === 'ERROR') {
          throw new Error(response.data.message);
        }
        this.$toaster.makeToast('Gespeichert!', 'Der Lead wurde erfolgreich gespeichert.');
        this.$emit('update:passedData', response.data);
      } catch (err) {
        console.error(err);
        if (err.response && err.response.data) {
          this.$toaster.makeToast('Fehler', `Der Lead konnte nicht gespeichert werden: ${err.response.data.message}`, 'danger');
        } else {
          this.$toaster.makeToast('Fehler', 'Ein unbekannter Fehler ist aufgetreten.', 'danger');
        }
      }
    },
      clickHandler() {
        this.$emit('submit', this.computedData)
      },
      getIconForActivityType(type) {
        return type.toUpperCase().includes('OPENED') ? 'cil-envelope-letter' : 'cil-envelope-closed'
      },
      async loadPipelines() {
        const ret = await axios.get('/pipeline')
        this.originalPipelines = ret.data || []
        this.pipelines = []
        if (ret.data && ret.data.length > 0) {
          this.pipelines = ret.data.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
        }
      },
      async loadPipelineDetail(id) {
        const ret = await axios.get(`/pipeline/${id}`)
        if (ret && ret.data) {
          this.selectedPipelineSteps = ret.data.pipelineSteps && ret.data.pipelineSteps.sort((a, b) => a.orderNumber - b.orderNumber)
          this.previousPipelineSteps = JSON.parse(JSON.stringify(this.selectedPipelineSteps))
        }
      },
      async applyAdd(deal) {
        this.visibleModal = false
      },
      async applyUpdate(deal) {
        this.visibleModal = false
      },
      submit() {
        this.$refs.dealWidget.submit()
      },
      submitForm() {
      this.$emit('submit', this.computedData)
      },
      showAddModal() {
        const firstPipeline = this.originalPipelines && this.originalPipelines.length > 0 && this.originalPipelines[0]
        const firstStep = firstPipeline && firstPipeline.pipelineSteps && firstPipeline.pipelineSteps[0]
        this.mode = 'Add'
        this.$refs.dealWidget.selectedPipeline = firstPipeline && firstPipeline.id
        this.$refs.dealWidget.currentStep = firstStep
        this.$refs.dealWidget.name = ''
        this.$refs.dealWidget.value = this.computedData && this.computedData.results && this.computedData.results.resultAbsolute
        this.$refs.dealWidget.selectedPeople = {
          value: '',
          text: '',
        }
        this.$refs.dealWidget.selectedGroup = {
          value: '',
          text: '',
        }
        this.$refs.dealWidget.leadPeopleName = this.computedData && this.computedData.holder && (this.computedData.holder.firstName + ' ' + this.computedData.holder.lastName)
        this.$refs.dealWidget.leadPeopleEmail = this.computedData && this.computedData.holder && this.computedData.holder.email
        this.$refs.dealWidget.leadPeoplePhone = this.computedData && this.computedData.holder && this.computedData.holder.phoneNumber
        this.visibleModal = true
      },
      closeDealWidget() {
        this.visibleModal = false
        this.$refs.dealWidget.showSimilarPeople = false
        this.$refs.dealWidget.submitted = false
      }
    }
  }
</script>

<style scoped>
.lead-form-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.main-content {
  width: 100%;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}

.sticky-header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.lead-info-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.lead-type {
  flex: 1;
  max-width: 300px;
}

.lead-details {
  text-align: right;
}

.lead-details p {
  margin: 0;
  line-height: 1.5;
}

.imported-label {
  color: #b70000;
  font-weight: 700;
}

.modern-tabs {
  margin-top: 20px;
}

.modern-tabs >>> .nav-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.modern-tabs >>> .nav-pills .nav-item {
  flex: 1;
  min-width: 120px;
}

.modern-tabs >>> .nav-pills .nav-link {
  width: 100%;
  text-align: center;
  border-radius: 20px;
  padding: 10px;
  background-color: #f8f9fa;
  color: #333;
  transition: all 0.3s ease;
}

.modern-tabs >>> .nav-pills .nav-link.active {
  background-color: #007bff;
  color: #fff;
}

.modern-tabs >>> .nav-pills .nav-link:hover {
  background-color: #e9ecef;
}

.tab-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.lead-form .form-control[readonly] {
  background-color: #f8f9fa;
  border-color: #e0e0e0;
}

.lead-activity {
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f8f9fa;
  transition: all 0.3s ease;
}

.lead-activity:hover {
  background-color: #e9ecef;
}

.lead-activity .lead-activity-created-format {
  display: none;
}

.lead-activity:hover .lead-activity-from-now {
  display: none;
}

.lead-activity:hover .lead-activity-created-format {
  display: inline;
}

.lead-activity h5 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.lead-activity p {
  margin: 10px 0 0;
  font-size: 14px;
}

.lead-activity .cil-envelope-letter,
.lead-activity .cil-envelope-closed {
  font-size: 20px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .sticky-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .action-buttons {
    margin-top: 10px;
  }

  .lead-info-card {
    flex-direction: column;
  }

  .lead-type,
  .lead-details {
    width: 100%;
    max-width: none;
    text-align: left;
  }

  .lead-details {
    margin-top: 10px;
  }

  .modern-tabs >>> .nav-pills {
    flex-direction: column;
  }

  .modern-tabs >>> .nav-pills .nav-item {
    width: 100%;
  }
}
</style>

