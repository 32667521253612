<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Freizugebener Lead bearbeiten</strong> #{{ $route.params.id }}
            </slot>
          </CCardHeader>
          <CCardBody>
            <LeadForm
                    v-if="ready"
                    @submit="saveLead"
                    :passed-data="lead"
                    btn-text="Speichern">
              <template v-slot:extraButtons>
                <CButton @click="saveAndContinueLead(lead)" color="info" style="margin-left: 4px">
                  Speichern und Wertermittlung senden
                </CButton>
              </template>
            </LeadForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
  import LeadForm from './LeadForm'
  import axios from "axios"
  export default {
    name: "EditQueuedLead",
    components: { LeadForm },
    data() {
      return {
        lead: null,
        ready: false
      }
    },
    created() {
      axios.get('/lead/' + this.$route.params.id)
          .then(response => {
            this.lead = response.data
            this.ready = true
          }).catch(err => console.error(err))
    },
    methods: {
      saveLead(lead) {
        axios.put('/lead', lead)
            .then(response => {
              this.$toaster.makeToast('Gespeichert!', 'Der Lead wurde erfolgreich gespeichert.')
              this.$router.push({ name: 'QueuedLeads' })
            })
            .catch(err => console.error(err))
      },
      async saveAndContinueLead(lead) {
        try {
          await axios.put('/lead', lead)
          await axios.get('/lead/continueQueuedLead/' + this.$route.params.id)
          this.$toaster.makeToast('Gespeichert!', 'Der Lead wurde erfolgreich gespeichert.')
          await this.$router.push({ name: 'QueuedLeads' })
        } catch (err) {
          console.error(err)
        }
      }
    }
  }
</script>